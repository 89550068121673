html{
  background-size: cover;
  margin: 0;
  padding: 0;
  background-color: #33c0ee;
  background-image: linear-gradient(0deg, #33c0ee 0%, #17e294 100%);
  filter: brightness(100%);
  width: 100%;
  height: 100%;
  position: center;
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
}

.App {
  text-align: center;
}

.App-link {
  color: #00A0B2;
}

.aboutbuttons{
  margin: auto;
  padding-bottom: 3%;
}

.buttonDivide{
  width: 25px;
  height:auto;
  display:inline-block;
}

.fab{
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
}

.homepage{
  padding-top: 0.6in;
  margin: auto;
  justify: center;
}

.logo{
  margin: auto;
  padding-right: 5%;
  width: 40px;
}

.search {
  font-weight: bold;
  padding-top: 0.2in;
  padding-bottom: 0.5in;
  margin: auto;
}

.slogan{
  padding-bottom: calc(5% + 10px);
}

.welcome{
  color: #000000;
}

.input{
  padding: 0.625rem;
  margin: auto;
}

.goHelper{
  animation: shadow-pulse 1.5s infinite;
}

.menuheading{
  margin: auto;
  padding-top: 2%;
}

.venuename{
  font-weight: bold;
  font-size: 1.375rem;
  color: #FFFFFF;
  text-decoration: none;
}

.rating{
  font-size: 1.32rem;
  font-weight: bold;
}

.barHeading{
  padding-top: 0.32rem;
}

.outer{
  width: 100%;
  text-align: center;
  padding-bottom: 2%;
}

.inner{
  display: inline-block;
}

.loading{
  margin:auto;
  text-align: center;
  padding-top: 33%;
}

.background{
  background-size: cover;
  background-color: #43626B;
  min-height: 100%;
}

.altBackground{
  background-size: cover;
  background: #F2CDCF;
  min-height: 100%;
  padding: 1.10rem;
}

.verifyBackground{
  background-size: cover;
  background: #D2F2DE;
  min-height: 100%;
  padding: 1.10rem;
}

.loadingDrinks{
  margin: auto;
  text-align: center;
  font-size: 1.25rem;
  padding: 0.32rem;
}

.menu{
  text-align: center;
  font-size: 1.125rem;
  color: #FFFFFF;
}

.noDrinks{
  font-weight: normal;
  font-size: 1.00rem;
}

.submit{
  padding: 0.625rem;
  text-align: center;
  margin:auto;
}
.size{
  text-align: left;
}

.submitTitle{
  text-align: center;
  font-weight: bold;
}

.about{
  text-align: center;
}

.foursquareAttr{
  height: 0.13in;
}

.sidebarLink{
  color: #000000;
  text-decoration: none;
}

.calcForm{
  margin: auto;
  width: 85%;
  background-color: #EAF2ED;
  padding-top: 5%;
  border-radius: 15px;
}

.calcButton{
  padding: 3%;
}

.calcInput{
  margin:auto;
}

.calcResult{
  padding: 4%;
}

.disclaimer{
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 0.65rem;
}

/*animation for button helper
 */
@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(105, 108, 109, 1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
